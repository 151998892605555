import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible'



router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
